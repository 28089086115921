
<template>
	<div>
		<v-container>
			<v-row>
				<div class="col-md-12 col-sm-12">
					<v-card dense>
						<v-row style="margin: auto;">
							<v-col
								align="center"
								cols="6"
								lg="3"
								md="4"
								v-for="item in itemsTunnel"
								:key="item.FtmID"
							>
								<!-- <v-btn
									x-small
									elevation="0"
									fab
									dark
									color="error"
									style="margin-left: 5px; margin-top: 0px"
								>
									<v-icon style="font-size: 16px !important"
										>fas fa-eye</v-icon
									>
								</v-btn> -->
								
								<v-card 
									class="mx-auto"
									dense
									:color="item.inTunnelStateColor"
									style="position: relative;display: inline-block;"
								><!-- item.inTunnelStateColor -->
									<!-- <v-card-title class="justify-center">
										<v-card :color="item.inTunnelStateColor" outlined width="200"><h4>{{ item.FtmDescription }}</h4></v-card>
										
									</v-card-title> ../../../assets/pallets44.png-->
									
									<v-img
										lazy-src="https://img.interempresas.net/fotos/2852264.jpeg"
										
										src="https://img.interempresas.net/fotos/2852264.jpeg"
										>
										
										<v-row style="margin: auto;">
											<v-col v-if="item.MtpWeekBatch != undefined" cols="12" class="mt-0">
												<!-- <v-btn  v-if="item.inParihuelaUsage > 0" small color="error"  style="z-index:;transform: rotateZ(0deg);margin-top: -2%;margin-left: -30%;position: absolute;width: 133px;border-radius: 20px;">Max. Dias Parih.: {{item.MtpDay}}</v-btn> -->
												<h3 style="position: absolute;top: 20%; left: 40%; 
													transform: translate(-20%, -50%);
													background-color: rgba(255, 255, 255, 0.7);
													padding: 10px;
													color:steelblue;
													font-weight: bold;
  													border-radius: 5px;">{{ item.MtpWeekBatch }}</h3>
											</v-col>
											<v-col cols="6" lg="6" class="mt-0">
												<!-- <h1 v-if="item.MtpTemperatureBegin > 0"><v-chip small color="info">Temp: {{item.MtpTemperatureBegin}}</v-chip></h1> -->
											</v-col>
											<v-col cols="6" lg="6" class="mt-0" v-if="item.inTunnelState == 2">
												<!-- <h1 v-if="item.MtpEthyleneHour > 0"><v-chip small color="info">H. Etile: {{item.MtpEthyleneHour}}</v-chip></h1> -->
											</v-col>
										</v-row>
										
										<v-btn
											style=" margin: auto; display: flex; justify-content: space-evenly; "
											:color="item.inColor"
											fab
											@click="openModal(item)"
											:class="item.MtpTemperatureBegin > 0 ? 'mt-1' : 'mt-8'"
											width="100"
											height="100"
											class="mb-8"
										>
											<span class="white--text text-h4"><b>
												<h1 style="font-size: 20px; ">
													{{item.FtmWeigthNet + ' TM'}}
												</h1>
											</b></span>
										</v-btn>
										<v-row  >
											<v-col >
												<v-row v-if="item.MtpTemperatureBegin > 0"  class="d-flex justify-center mb-6 bg-surface-variant">																								
													<v-chip small :color="item.inTunnelStateColor" style="font-size: 12px; "> Temp: {{ item.MtpTemperatureBegin }}</v-chip>
												</v-row>
											</v-col>
											<v-col >
												<v-row v-if="item.MtpEthyleneHour > 0"  class="d-flex justify-center mb-6 bg-surface-variant" >																					
													<v-chip small :color="item.inTunnelStateColor"  style="font-size: 12px; "> H. Etileno: {{ item.MtpEthyleneHour }}</v-chip>
												</v-row>
											</v-col>
										</v-row>							
										
									</v-img>									
									<v-card :color="item.inTunnelStateColor" outlined width="200">
										<v-btn
											style="position: absolute;top: 0%; left: 0%;transform: translate(-10%, -20%);"
											small
											fab
											elevation="3"
											color="info"
											v-if="item.inParihuelaUsage > 0"
											@click="openDialogExitTunnel(item)"			
																	
										>
											<v-icon style="font-size: 16px !important"
												>fas fa-eye</v-icon>
										</v-btn>
										<h3 style="position: relative;display: inline-block;top: 0%; left: 0%;">{{ item.FtmDescription }}</h3>
										<v-btn small
											
											style="position: absolute;top: 0%; left: 90%;transform: translate(-10%, -20%);"
											fab
											elevation="3"
											color="#FC5335"
											v-if="item.MtsID == 1"
											@click="openDialogWeekBatch(item)"	>
											<v-icon style="font-size: 16px !important"
												>mdi-calendar</v-icon>
										</v-btn>
									</v-card>
									
								</v-card>								
								<v-row >
									<v-col cols="auto">
										<s-select											
											class="mt-1"
											:items="itemsStateTunnel"
											item-value="MtsID"
											item-text="MtsDescription"
											return-object
											v-model="item.inTunnelState"
											@input="inputState($event, item)"
										>
										</s-select>
									</v-col>
									<v-col cols="auto">
										<!-- <v-btn
											
											small
											fab
											elevation="3"
											color="info"
											v-if="item.inParihuelaUsage > 0"
											@click="openDialogExitTunnel(item)"			
																	
										>
											<v-icon style="font-size: 16px !important"
												>fas fa-eye</v-icon>
										</v-btn> -->
									</v-col>
									<v-col cols="auto">
										<!-- <v-btn small
											fab
											elevation="3"
											color="#FC5335"
											v-if="item.inParihuelaUsage > 0"
											@click="openDialogWeekBatch(item)"	>
											<v-icon style="font-size: 16px !important"
												>mdi-calendar</v-icon>
										</v-btn> -->
									</v-col>
								</v-row>
								<!-- <s-select-definition class="mt-2" :def="1359" v-model="item.inTunnelState"></s-select-definition> -->								
							</v-col>
						</v-row>
					</v-card>
				</div>
			</v-row>
		</v-container>

		<v-dialog
			v-model="dialog"
			v-if="dialog"
			transition="dialog-bottom-transition"
			persistent
			fullscreen>
			<pallet-in-tunnel-detail
				@closeDetail = "closeDetail"				
				:dataTunnel="dataTunnel"
				:FtmID="FtmID">
			</pallet-in-tunnel-detail>
			<!-- :dataPrihuela="dataPrihuela" -->
		</v-dialog>

		<v-dialog
				v-model="dialogExitTunnel"
				v-if="dialogExitTunnel"
				width="500"
				persistent>
			<v-card>
				<v-container>
					<v-row>
						<s-toolbar
							color="#BDBDBD"
							label="Parametros"
							save
							@save="saveProcessGroup()"
							close
							@close="closeDialogExitTunnel()"
							dark
						>

						</s-toolbar>
					</v-row>
					<v-row>
						<v-col>
							<s-text
								decimal
								v-model="processGroup.MtpTemperatureBegin"
								label="Temperatura">
							</s-text>
						</v-col>
						<v-col>
							<s-text
								decimal
								v-model="processGroup.MtpEthyleneHour"
								label="Horas etileno">
							</s-text>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>

		<v-dialog
				v-model="dialogWeekBatch"
				v-if="dialogWeekBatch"
				width="500"
				persistent>
			<v-card>
				<v-container>
					<v-row>
						<s-toolbar
							color="#BDBDBD"
							label="Parametros"
							save
							@save="saveProcessGroup()"
							close
							@close="closeDialogExitTunnel()"
							dark
						>

						</s-toolbar>
					</v-row>
					<v-row>
						<v-col>
							<s-text
								decimal
								v-model="processGroup.MtpWeek"
								label="Semana">
							</s-text>
						</v-col>
						<v-col>
							<s-text
								decimal
								v-model="processGroup.MtpBatch"
								label="Batch">
							</s-text>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>

		<v-dialog
				v-model="dialogTimeDate"
				v-if="dialogTimeDate"
				width="500"
				persistent>
			<v-card>
				<v-container>
					<v-row>
						<s-toolbar
							color="#BDBDBD"
							label="Parametros"
							save
							@save="saveTimeDate()"
							close
							@close="closeDialogTimeDate()"
							dark
						>

						</s-toolbar>
					</v-row>
					<v-row>
						<v-col>
							<s-date 
								
								v-model="processGroup.MtpDate"
								label="Fecha">
							</s-date>
						</v-col>
						<v-col>
							<label slot="before" aria-hidden="true" class="v-label theme--light"><b>Hora de ingreso a proceso</b></label>
                    		<div class="relojito">
								<datetime
									valueZone="America/Lima"  type="time"
									v-model="processGroup.PprHour"
									label="Hora">
								</datetime>
							</div>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import _sMaturationTunnelPalletService from '@/services/FrozenProduction/MaturationTunnelPalletService';
	import sTunnelMaduration from "../../../services/FrozenProduction/TunnelMadurationService";
	import PalletInTunnelDetail from './PalletInTunnelDetail.vue';
	import signalr from "signalr";
	import { Datetime } from 'vue-datetime';

	export default {
		components: { 
			PalletInTunnelDetail ,
			Datetime
		},

		data() {
			return {
				processGroup: {},
				dialogExitTunnel: false,
				itemsTunnel: [],
				itemsStateTunnel: [],
				dataTunnel: {},
				dialog:false,
				dataPrihuela: {},
				skill: 20,
				statusTunnel: 0,
				FtmID:0,
				statusTunnelName: '',
				itemsTempreratureEthylene: [],
				Continue:false,
				dialogWeekBatch: false,
				dialogTimeDate: false,
				
			}
		},

		watch: {
			"item.inTunnelState"(newValue, oldValue) {
				
			}
		},

		methods: {

			saveProcessGroup()
			{
				
				this.$fun.alert("¿Seguro de guardar?", "question")
				.then(r => {
					if(r.value){
						_sMaturationTunnelPalletService
						.beginprocessgroup(this.processGroup ,this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200){
								this.$fun.alert("Guardado Correctamente", "success");
								this.closeDialogExitTunnel();
							}
						})
					}
				})
			},

			openDialogExitTunnel(item)
			{
				this.processGroup.xFlag = 1;
				this.processGroup.TcaID = item.FtmID;
				this.processGroup.UsrUpdateID = this.$fun.getUserID();
				this.dialogExitTunnel = true;
			},

			closeDialogTimeDate(){
				this.dialogTimeDate = false;
				this.initialize();
			},

			openDialogWeekBatch(item)
			{
				this.processGroup.xFlag = 1;
				this.processGroup.TcaID = item.FtmID;
				this.processGroup.UsrUpdateID = this.$fun.getUserID();
				this.dialogWeekBatch = true;
			},

			closeDialogExitTunnel()
			{
				this.dialogExitTunnel = false;
				this.dialogWeekBatch = false;
				this.initialize()
			},

			initialize(){

				_sMaturationTunnelPalletService.listStateMaturation(this.$fun.getUserID()).then(r => {
					if(r.status == 200){
						this.itemsStateTunnel = r.data						
					}
				})


				sTunnelMaduration.list({}, this.$fun.getUserID()).then(r => {
					if(r.status == 200){

						r.data.forEach(element => {
							element.ParihuelaUsagePorcentaje = (100 * element.inParihuelaUsage) /  parseInt(element.FtmCapacity)
							element.ParihuelaAvailableUnit =   parseInt(element.FtmCapacity)- parseInt(element.inParihuelaUsage)
							
							
							//Capacidad llena
							if(element.ParihuelaUsagePorcentaje == 100){
								element.inColor = 'error'
							}

							//Tunnel por llenarse
							if(element.ParihuelaUsagePorcentaje >= 80 && element.ParihuelaUsagePorcentaje < 100){
								element.inColor = 'warning'
							}

							if(element.ParihuelaUsagePorcentaje > 35 && element.ParihuelaUsagePorcentaje <= 79){
								element.inColor = 'info'
							}

							if(element.ParihuelaUsagePorcentaje <= 35){
								element.inColor = 'success'
							}
	
						});

						this.itemsTunnel = r.data;
						console.log(this.itemsTunnel);
					}
					
				});
			},
			saveTimeDate(){
				this.processGroup.MtsID = this.item.MtsID;
				this.processGroup.FtmID = this.FtmID;
				this.processGroup.UsrCreateID = this.$fun.getUserID();
				
				_sMaturationTunnelPalletService
					.saveTimeDate(this.processGroup , this.$fun.getUserID())
					.then(resp => {
						if(resp.status == 200){
							this.$fun.alert("Guardado Correctamente", "success");
							this.closeDialogTimeDate();
						}
					})
			},
			inputState(item, itemTunnel)
			{			
				
				this.item = item;
				this.FtmID = itemTunnel.FtmID;	
				if(item == itemTunnel.MtsID ){
						return
					}	
					
				if(item.MtsID < itemTunnel.MtsID)
				{
					this.$fun.alert("¿Error, no esta permitido cambiar de Estado " + itemTunnel.inTunnelStateName +" a " +item.MtsDescription+", Desea Continuar?", "question").then(val => {
						if(val.value)
						{
							this.saveState(item, itemTunnel);
						}else
						{
							this.initialize()
						}
					});					
				}else{
					this.saveState(item, itemTunnel);
					
				}
			},

			saveState(item, itemTunnel)
			{
				itemTunnel.ChangeState = 10
				itemTunnel.MtsID = item.MtsID
				itemTunnel.UsrUpdateID = this.$fun.getUserID();

				this.statusTunnel = item.MtsID;
				this.statusTunnelName = item.MtsDescription;

				
				this.$fun.alert("¿Seguro de Cambiar de Estado de " + itemTunnel.FtmDescription +" a " +item.MtsDescription+"?", "question").then(val => {
					if(val.value){
						
						sTunnelMaduration.save(itemTunnel,this.$fun.getUserID() ).then(resp => {
							if(resp.status == 200){ 
								this.$fun.alert("Cambio realizado correctamente", "success")
								
								this.dialogTimeDate = true;
							}
						})
					}
					this.initialize();

				})
			},

			openModal(item)
			{				
				this.dataTunnel = item;
				this.FtmID = item.FtmID;
                
				this.dialog = true;
				// let filter = {
				// 	FtmID: item.FtmID,
				// }

				// _sMaturationTunnelPalletService
				// .list(filter, this.$fun.getUserID()).then(resp => {
				// 	if(resp.status == 200){

				// 		resp.data.forEach(element => {
				// 			element.MtpDateBegin = this.$moment(element.MtpDateBegin).format(
				// 				this.$const.FormatDateTimeDB
				// 			);
				// 			element.MtpSecondsNew = 
				// 				this.$fun.isDifferenceMinutes(
				// 					element.MtpDateNewBegin,
				// 					element.MtpDateNewEnd,
				// 					"seconds"
				// 				) * -1;

				// 			element.MtpMinuteNew = this.$fun.secondsToString(element.MtpSecondsNew)
				// 		});

				// 		this.dataPrihuela = resp.data;
						
				// 	}
				// })

				
			},

			closeDetail()
			{
				this.dialog = false;
				this.initialize()
			}

			/* Initialize() {
				_sMaturationTunnelPalletService.palletsinside(this.$fun.getUserID())
				.then(resp => {	
					if(resp.status == 200){
						this.destinations = resp.data;
						this.destinations = this.destinations.filter( e => {
							return e.DedDescription != "Californiano"
						});
						this.destinations = this.destinations.filter( e => {
							return e.DedDescription != "Selección"
						});
						this.destinations = this.destinations.filter( e => {
							return e.DedDescription != "Proceso"
						});
					}
				})
			}, */

			

			
		},

		created () {
			this.initialize();
			this.processGroup.PprHour = this.$fun.formatTimeShortView(this.$moment().format("HH:mm"));
		},

		mounted() {
			
			let recaptchaScript = document.createElement("script");
			recaptchaScript.setAttribute("src", this.$const.URL + "/signalr/hubs");
			document.head.appendChild(recaptchaScript);

			recaptchaScript.addEventListener("load", () => {
				$.connection.hub.url = this.$const.URL + "/signalr";
				var chat = $.connection.notification;
				chat.client.FrozenEntryParihuelaIntunnel = group => {
					this.initialize()
				};

				$.connection.hub.start({ jsonp: true }).done(e => {
					chat.invoke("addToGroup", "frozenUpdateWaitingEntryParihuelaIntunnel");
				});
			});
		},
	}
</script>
<style>
.relojito {
	width:auto;
	height: auto;
	border-width: 1px;
	border-style: solid;
	border-color: rgb(160, 159, 159);
	border-radius: 4px;
	padding: 3px;
  }
  </style>